'use strict';

var $ = require('jquery');
var Siema = require('siema');
var basePage = require('../pages/base-page.js');
var layout = require('../ui/layout.js');
var images = require('../ui/images.js');
var scroll = require('../ui/scroll.js');
var analytics = require('../utils/analytics.js');

var classNames = {
	actionBox: 'action-box',
	contextOptions: 'context-options',
	contextQuickFinds: 'context-quick-finds',
	radioOption: 'radio-option',
	radioOptionInput: 'radio-option__input',
	logoSlider: 'logo-slider',
	manufacturerLogos: 'manufacturer-logos',
	logoNav: 'nav'
};

var modifierNames = {
	next: 'next',
	prev: 'prev'
};

function initLogoSlider() {
	var $slider = $.findByBem(classNames.logoSlider);

	if ($slider.length > 0) {
		var logoSlider = new Siema({
			selector: '.' + classNames.logoSlider,
			perPage: 10
		});

		$.findByBem(classNames.manufacturerLogos, classNames.logoNav, modifierNames.prev).on('click.slider', function(e) {
			e.preventDefault();
			logoSlider.prev();
		});

		$.findByBem(classNames.manufacturerLogos, classNames.logoNav, modifierNames.next).on('click.slider', function(e) {
			e.preventDefault();
			logoSlider.next();
		});
	}	
}

function logPanelSwitchEvent(currentPanel, newPanel) {
	if (currentPanel !== newPanel) {
		analytics.trackEvent('Homepage uber-tab', 'Tab change', currentPanel + ' -> ' + newPanel);
	}
}

function togglePanels($targetPanel) {
	var $otherPanels = $targetPanel.siblings();
	var $container = $.findByBem(classNames.actionBox);
	var currentActivePanel = $container.data('active-panel');
	var newActivePanel = $targetPanel.data('panel');

	logPanelSwitchEvent(currentActivePanel, newActivePanel);

	if (layout.isMobile()) {
		$otherPanels.removeBemClass(classNames.actionBox, 'panel', 'active').findByBem(classNames.actionBox, 'panel__content').hide();
		$targetPanel.addBemClass(classNames.actionBox, 'panel', 'active').findByBem(classNames.actionBox, 'panel__content').slideDown();
		scroll.scrollTo($targetPanel, 400);
	} else {
		$otherPanels.removeBemClass(classNames.actionBox, 'panel', 'active');
		$targetPanel.addBemClass(classNames.actionBox, 'panel', 'active');

		$container.removeBemClass(classNames.actionBox, null, currentActivePanel);
		$container.addBemClass(classNames.actionBox, null, newActivePanel);
		$container.data('active-panel', newActivePanel);
		images.loadImagesInView();
	}
}

function initActionBox(context) {
	$.findByBem(classNames.actionBox, 'panel__heading', null, context).on('click.action-box-heading', function (e) {
		e.preventDefault();
		togglePanels($(this).parent());
	});

	$.findByBem(classNames.actionBox, 'panel__expander', null, context).on('click.action-box-expander', function () {
		togglePanels($(this).parent());
	});
}

function initReviewsTabOptions(context) {
	var reviewTab = $.findByBem(classNames.actionBox, 'panel__content', 'reviews-and-specs', context);

	var $contextOptions = $.findByBem(classNames.contextOptions, null, null, reviewTab);
	var $contextQuickFinds = $.findByBem(classNames.contextQuickFinds, null, null, reviewTab);

	$contextOptions.findByBem(classNames.radioOption).on('click.context-option', function () {
		$contextQuickFinds.findByBem('panel', 'tab-content').removeClass('active');

		var targetQuickFinds = $(this).findByBem(classNames.radioOption, 'input').val();
		$('#' + targetQuickFinds).addClass('active');
	});
}

basePage.init = function() {
	basePage.initBasePage();

	var $context = $('#homePage');

	initActionBox($context);
	initReviewsTabOptions($context);
	initLogoSlider();
	
};

module.exports = basePage; 